import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import pythonLogo from '../assets/img/Python-Symbol.png';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <section className="skill" id="skills">
      <h2>Strengths<img src={pythonLogo} alt="Python Logo" id="logo" /></h2>
      <div className="columns equal-columns">
        <div className="bg-pink">
          <h3>Data Engineering</h3>
          <ul className="flex-container">
          <li className="flex-item">DBT</li>
          <li className="flex-item">BigQuery</li>
          <li className="flex-item">Hadoop</li>
          <li className="flex-item">MrJob</li>
          <li className="flex-item">SQL</li>
          <li className="flex-item">NoSQL</li>
          <li className="flex-item">MySQL</li>
          <li className="flex-item">PostgreSQL</li>
          <li className="flex-item">Adverity</li>
          <li className="flex-item">Airflow</li>
          <li className="flex-item">Pyspark</li>
          <li className="flex-item">Pandas</li>
          <li className="flex-item">Numpy</li>
          <li className="flex-item">ETL</li>
          <li className="flex-item">Tableau</li>
          <li className="flex-item">Power BI</li>
          <li className="flex-item">SQLite</li>
          </ul>
        </div>
        <div className="bg-orange">
          <h3>Cloud</h3>
          <ul className="flex-container">
          <li className="flex-item">AWS</li>
          <li className="flex-item">GCP</li>
          <li className="flex-item">GCP BigQuery</li>
          <li className="flex-item">Azure</li>
          <li className="flex-item">AWS EC2</li>
          <li className="flex-item">GCP Compute Engine</li>
          <li className="flex-item">AWS RDS</li>
          <li className="flex-item">AWS Lambda</li>
          <li className="flex-item">AWS EMR</li>
          <li className="flex-item">Sentry</li>
          <li className="flex-item">AWS S3</li>
          <li className="flex-item">GCP Storage</li>
          <li className="flex-item">Azure MLStudio</li>
          <li className="flex-item">GCP Firestore</li>
          <li className="flex-item">AWS Elastic BeanStalk</li>
          <li className="flex-item">Datadog</li>
          <li className="flex-item">AWS Comprehend</li>
          <li className="flex-item">AWS Rekognition</li>
          </ul>
        </div>
        <div className="bg-orange">
          <h3>Software Engineering</h3>
          <ul className="flex-container">
          <li className="flex-item">Django</li>
          <li className="flex-item">Docker</li>
          <li className="flex-item">Flask</li>
          <li className="flex-item">FastAPI</li>
          <li className="flex-item">Big O</li>
          <li className="flex-item">REST</li>
          <li className="flex-item">Git</li>
          <li className="flex-item">Linux</li>
          <li className="flex-item">Multiprocessing</li>
          <li className="flex-item">CI/CD</li>
          <li className="flex-item">R</li>
          <li className="flex-item">UML</li>
          <li className="flex-item">Architecture</li>
          <li className="flex-item">DDD</li>
          <li className="flex-item">Redis</li>
          <li className="flex-item">Celery</li>
          <li className="flex-item">Silk profiler</li>
          <li className="flex-item">Re</li>
          <li className="flex-item">Asyncio</li>
          <li className="flex-item">C++</li>
          <li className="flex-item">Pre-commit</li>
          <li className="flex-item">Prospector</li>
          <li className="flex-item">YAML</li>
          <li className="flex-item">Makefile</li>
          <li className="flex-item">Docker-Compose</li>
          <li className="flex-item">NginX</li>
          <li className="flex-item">Swarm</li>
          <li className="flex-item">Kubernetes</li>
          <li className="flex-item">Pytest</li>
          <li className="flex-item">UnitTest</li>
          <li className="flex-item">Gherkin</li>
          <li className="flex-item">Factory</li>
          <li className="flex-item">TDD</li>
          <li className="flex-item">E2E</li>
          <li className="flex-item">Requests</li>
          <li className="flex-item">Stripe</li>
          <li className="flex-item">Postman</li>
          <li className="flex-item">Selenium</li>
          <li className="flex-item">Random</li>
          <li className="flex-item">Ta</li>
          <li className="flex-item">DevOps</li>
          <li className="flex-item">React</li>
          </ul>
        </div>
        <div className="bg-red" >
          <h3>Machine Learning</h3>
          <ul className="flex-container">
          <li className="flex-item">NLP</li>
          <li className="flex-item">Scikit-learn</li>
          <li className="flex-item">Statistics</li>
          <li className="flex-item">Mathematics</li>
          <li className="flex-item">PCA</li>
          <li className="flex-item">OCR</li>
          <li className="flex-item">Deep Learning</li>
          <li className="flex-item">Computer Vision</li>
          <li className="flex-item">XGMBoost</li>
          <li className="flex-item">Supervised</li>
          <li className="flex-item">Unsupervised</li>
          <li className="flex-item">Clustering</li>
          <li className="flex-item">Association rules</li>
          <li className="flex-item">Random Forest</li>
          <li className="flex-item">Regression</li>
          <li className="flex-item">RNN</li>
          <li className="flex-item">LSTM</li>
          <li className="flex-item">Neural Networks</li>
          <li className="flex-item">SVM</li>
          <li className="flex-item">Word2Vec</li>
          <li className="flex-item">Bert</li>
          <li className="flex-item">NLTK</li>
          <li className="flex-item">ARIMA</li>
          <li className="flex-item">Anomaly Detection</li>
          <li className="flex-item">Ensemble</li>
          <li className="flex-item">Keras</li>
          <li className="flex-item">Transformers</li>
          <li className="flex-item">SciPy</li>
          <li className="flex-item">Tensorflow</li>
          <li className="flex-item">Recommender</li>
          <li className="flex-item">Matplotlib</li>
          <li className="flex-item">Seaborn</li>
        </ul>
        </div>
      </div>
      {/*</td>*/}
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
