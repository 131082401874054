import cert1 from "../assets/img/certifications/cert1.png"
import cert2 from "../assets/img/certifications/cert2.png"
import cert3 from "../assets/img/certifications/cert3.png"
import cert4 from "../assets/img/certifications/cert4.png"
import cert5 from "../assets/img/certifications/cert5.png"
import cert6 from "../assets/img/certifications/cert6.png"
import cert7 from "../assets/img/certifications/cert7.jpg"
import cert8 from "../assets/img/certifications/cert8.jpg"
import cert9 from "../assets/img/certifications/cert9.jpg"
import cert10 from "../assets/img/certifications/cert10.jpg"
import cert11 from "../assets/img/certifications/cert11.jpg"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Certifications = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="cert" id="certificates">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">

                        <h2>Certificates</h2>
                        <p>I actively participate in certification courses to extend my knowledge in exciting fields of study.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <a href={"https://www.coursera.org/account/accomplishments/certificate/ZAQ883NVWFBR"}>
                                <img src={cert1} alt="Image" />
                                </a>
                                <h5>Neural Networks and Deep Learning</h5>
                            </div>
                            <div className="item">
                                <a href={"https://www.coursera.org/account/accomplishments/certificate/K7Y2UG7LZGGT"}>
                                <img src={cert2} alt="Image" />
                                </a>
                                <h5>Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optymization</h5>
                            </div>
                            <div className="item">
                                <a href={"https://www.coursera.org/account/accomplishments/certificate/ZXFABX8Z66DY"}>
                                <img src={cert3} alt="Image" />
                                </a>
                                <h5>Structuring Machine Learning Projects</h5>
                            </div>
                            <div className="item">
                                <a href={"https://coursera.org/share/6d7a15392a75ab438b61f1dc35858630"}>
                                <img src={cert4} alt="Image" />
                                </a>
                                <h5>Convolutional Neural Networks</h5>
                            </div>
                            <div className="item">
                                <a href={"https://coursera.org/share/c7420374cd592bc45d1aefcdddc4caa7"}>
                                <img src={cert5} alt="Image" />
                                </a>
                                <h5>Sequence Models</h5>
                            </div>
                            <div className="item">
                                <a href={"https://www.coursera.org/account/accomplishments/specialization/certificate/JSA5VJ6K9Y5Q"}>
                                <img src={cert6} alt="Image" />
                                </a>
                                <h5>Deep Learning Specialization</h5>
                            </div>
                            <div className="item">
                                <a href={"https://coursera.org/share/6d7a15392a75ab438b61f1dc35858630"}>
                                <img src={cert7} alt="Image" />
                                </a>
                                <h5>Convolutional Neural Networks</h5>
                            </div>
                            <div className="item">
                                <a href={"http://ude.my/UC-051d3cad-c144-4647-9321-2503426d483c"}>
                                <img src={cert8} alt="Image" />
                                </a>
                                <h5>MySQL for Data Analytics and Business Intelligence</h5>
                            </div>
                            <div className="item">
                                <a href={"http://ude.my/UC-20e3438f-d475-4ff8-a37e-5c8855f63a6a"}>
                                <img src={cert9} alt="Image" />
                                </a>
                                <h5>Big Data, Hadoop and Map Reduce</h5>
                            </div>
                            <div className="item">
                                <a href={"http://ude.my/UC-2c8cd4f9-f70d-4af9-888f-efb523b56b90"}>
                                <img src={cert10} alt="Image" />
                                </a>
                                <h5>REST API Development</h5>
                            </div>
                            <div className="item">
                                <a href={"http://ude.my/UC-58b6f1df-f408-4478-9956-3cc3e6ec6871"}>
                                <img src={cert11} alt="Image" />
                                </a>
                                <h5>Docker Master Class for Machine Learning and Data Science</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
