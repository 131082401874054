import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import headerImg from "../assets/img/header-img-hor.png";
import {ArrowRightCircle} from 'react-bootstrap-icons';
import {HashLink} from 'react-router-hash-link';
import {BrowserRouter as Router} from "react-router-dom";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Data Science", "Web Developer", "Machine Learning Engineer"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Welcome to my Portfolio</span>
                <h1>{`Hi! I'm Filip`} </h1>
                <h2><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Data Science", "Web Developer", "Machine Learning Engineer" ]'><span className="wrap">{text}</span></span></h2>
                  <p>
                    My mission is to deliver AI powered product which will make a big impact on the IT word.
                  My personal philosophy is to think that nothing is impossible, there are just various amounts of time to achieve it.
                  </p>
                  <Router>
                  <HashLink style={{ color: 'inherit', textDecoration: 'inherit'}} to='#connect'>
                  <button>Let’s Connect <ArrowRightCircle size={25} /></button>
                  </HashLink>
                  </Router>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
