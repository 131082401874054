import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import {ProjectCard} from "./ProjectCard";
import projImgTechRecommender from "../assets/img/projects/project-img-tech-recommender.png"
import projImgDroneClassificator from "../assets/img/projects/project-img-drone-classificator.png"
import projImgScorePredictor from "../assets/img/projects/project-img-xgm-classifier.png"
import projImgBookLibrary from "../assets/img/projects/project-img-book-library.png"
import projImgTrading from "../assets/img/projects/proj-img-trading.png"
import projImgCars from "../assets/img/projects/proj-img-cars-api.png"
import projImgDjango from "../assets/img/projects/proj-img-django.png"
import projImgDbt from "../assets/img/projects/proj-img-dbt.png"
import projImgTool from "../assets/img/projects/proj-img-cli-tool.png"
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projectsDS = [
    {
      title: "Tech recommender",
      description: "system that forecast possible know technologies for a people with a different specialities.",
      imgUrl: projImgTechRecommender,
      link: "https://github.com/Filip-231/SXI-Tech-Recommender",
    },
    {
      title: "Album score predictor",
      description: "based on gradient boosting regressor which was compared with linear model by evaluating metrics" +
          " and residuals plot. Good example for a concept drift with a certain longitudinal split. ",
      imgUrl: projImgScorePredictor,
      link: "https://github.com/Filip-231/PN-ML-Challenge",
    },
    {
      title: "Drone / Plane classificator",
      description: "which is able to determine if on the picture there is a plane or a drone based the ANN and CNN networks architecture (Keras Tensorflow.)",
      imgUrl: projImgDroneClassificator,
      link: "https://github.com/Filip-231/Deep-Learning-Convolutional-Neural-Networks",
    }];

  const projectsWD = [
    {
      title: "Book library app. (Flask)",
      description: "Application used for book\n" +
          "trading hosted on AWS Elastic Beanstalk with\n" +
          "AWS RDS.",
      imgUrl: projImgBookLibrary,
      link: "https://github.com/Filip-231/Book-Library-App",
    },
    {
      title: "Algorithmic trading.\n",
      description: "I created FastAPI with multiply page Streamlit app for forecasting stock prices. " +
          "I stream data from Binance API to SQLite and forecast using ARIMA and Fbprophet models.",
      imgUrl: projImgTrading,
      link: "https://github.com/Filip-231/AV-Binance-Trading",
    },
    {
      title: "Cars API (Django Rest)",
      description: "REST API which allow adding in case it exists in external online catalogue, deleting and updating the car information." +
          " User can rate the car and review the average score.",
      imgUrl: projImgCars,
      link: "https://github.com/Filip-231/NG-CarApp",
    },
  ];

  const projectsDO = [
    {
      title: "Django",
      description: "Complete CI/CD configuration for Django production web application.",
      imgUrl: projImgDjango,
      link: "https://github.com/Filip-231/BP-Test-Django",
    },
    {
      title: "DBT",
      description: "Complete CI/CD configuration for data warehouse.",
      imgUrl: projImgDbt,
      link: "https://github.com/Filip-231/BP-Test-Dbt",
    },
    {
      title: "CLI",
      description: "Complete CI/CD configuration for command line interface tool.",
      imgUrl: projImgTool,
      link: "https://github.com/Filip-231/BP-Test-Package",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>My own projects</h2>
                <p>I have over 4 years of professional experience in the full software development cycle in several projects listed below.
                    Projects were on different stages, from architecture development, through MVP, to maintenance.
                    I'm developing well-tested code infrastructure in accordance to design patterns and best practices.
                </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Data Science</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Web development</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Dev Ops</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <p>I feel satisfied using my Mathematical background in practical AI powered products.</p>
                      <Row>
                        {
                          projectsDS.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>I have a strong expertise in web development which makes me understand of how to efficiently deploy ML models and design architecture.</p>
                       <Row>
                        {
                          projectsWD.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>I'm a big fan of self made generic CI/CD approach for scalable, robust, optimized solutions maintained in cloud environment. </p>
                      <Row>
                        {
                          projectsDO.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
