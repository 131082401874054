import {Col, Container, Row, Tab} from "react-bootstrap";
import {ProjectCard} from "./ProjectCard";
import projImgUndisclosed from "../assets/img/projects/proj-img-undisclosed.jpeg"
import projImgBal from "../assets/img/projects/proj-bal-logo.png"
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';


export const Experience = () => {

  const projects = [
    {
      title: "NLP powered API (Azure)",
      description: "outputting novelty, topic and sentiment score " +
          "of events using BERT deep learning architecture. "+
          "Build pipeline for deploying, training and performance reporting. " +
          "Design data model and create business intelligence reports for visualizations.",
      imgUrl: projImgUndisclosed,
    },
    {
      title: "Big data warehouse (GCP)",
      description: " based on high scalable microservices infrastructure. " +
          "Perform deep marketing analysis and develop ML models to assess which ads will be profitable. ",
      imgUrl: projImgUndisclosed,
    },
    {
      title: "Health care app (AWS)",
      description: "recommending specialists in order to pursue customers personal goals like quit smoking, lose weight. " +
                "Orchestrate high capability cloud infrastructure, detect bottlenecks and optimize endpoints using cache and queues.",
      imgUrl: projImgUndisclosed,
    },
    {
      title: "Recommendation system",
      description: "which finds the most suitable people for a given project," +
          " forecasting familiar technologies and calculate team friendly index.",
          imgUrl: projImgUndisclosed,
    },
    {
      title: "Logistic app",
      description: " calculating transport prices, forecasting costs and wear of parts based on web scraped data.",
          imgUrl: projImgBal,
    },
    ];

  return (
    <section className="project" id="experience">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Professional Experience</h2>
                <p>I have over 4 years of professional experience in the full software development cycle in several projects listed below.
                    Projects were on different stages, from architecture development, through MVP, to maintenance.
                    I'm developing well-tested code infrastructure in accordance to design patterns and best practices.
                </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
