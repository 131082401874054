import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, link }) => {
  return (

    <Col size={12} sm={6} md={4}>
      <a href={link}>
      <div className="proj-imgbx" data={title} >
        <div className="proj-img" >
            <img src={imgUrl} />
        </div>
        <div className="proj-txtx">
          <span>{description}</span>
        </div>
      </div>
      </a>
    </Col>
  )
}
